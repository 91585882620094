import React from 'react';
import { graphql } from 'gatsby';
import Content from './../components/content/';
import Wrapper from './../components/wrapper/';
import WrapperContent from './../components/wrapperContent';
import QuickContact from './../components/quickContact';
import ApplyForm from './../containers/applyForm';
import VacancyHeader from './../containers/vacancyHeader';
import { LanguageLayout } from '../containers/layouts';
import SocialBar from './../components/socialBar';

export const VacancyDetailTemplate = ({ frontmatter, body, contact, slug }) => (
    <article>
        <VacancyHeader frontmatter={frontmatter} />
        <Wrapper>
            <WrapperContent>
                <Content html={body}/>
                <SocialBar slug={slug} messageID="socialBar.vacancy.socialMessage" pageTitle={frontmatter.title} noBorder email />
            </WrapperContent>
        </Wrapper>
        {contact &&
        <Wrapper>
            <WrapperContent color="grey" artifacts={false} inline>
                <ApplyForm vacancyID={frontmatter.title}/>
            </WrapperContent>
            <WrapperContent component color="blue">
                <QuickContact
                    title={contact.title}
                    text={contact.text}
                />
            </WrapperContent>
        </Wrapper>
        }
    </article>
);

const VacancyDetails = (props) => {
    if (props.data.page.edges.length > 0) {
        let contactFormatted = {};
        const { frontmatter, fields } = props.data.page.edges[0].node;
        const contact = props.data.contacts.edges.find(contact => contact.node.frontmatter.vacancycontactName === frontmatter.contact);

        if (contact) {
            contactFormatted = {
                title: contact.node.frontmatter.vacancycontactTitle,
                text: contact.node.fields.frontmattermd.vacancycontactText.html,
            }
        }

        // enhange data with expertise slug information
        const expertiseData = props.data.expertises.edges.find(expertise => expertise.node.frontmatter.id === frontmatter.expertise || expertise.node.frontmatter.title === frontmatter.expertise);
        frontmatter.expertise = (expertiseData) ? expertiseData.node.frontmatter.title : frontmatter.expertise;
        frontmatter.expertiseSlug = (expertiseData) ? expertiseData.node.fields.slug : null;

        return (
            <LanguageLayout location={props.location}>
                <VacancyDetailTemplate
                    frontmatter={frontmatter}
                    slug={fields.slug}
                    body={fields.frontmattermd.bodyText.html}
                    contact={(contact) ? contactFormatted : null}
                />
            </LanguageLayout>
        );
    }

    return <div>Page not found</div>
};

export default VacancyDetails;

export const vacancyDetailQuery = graphql`
query VacancyBySlug($langKey: String!, $slug: String!) {
    page: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "vacancy-page"}}, fields: {slug: {eq: $slug}}}) {
        edges {
            node {
                id
                frontmatter {
                    title
                    duration
                    hoursperweek
                    type
                    expertise
                    contact
                }
                fields {
                    slug
                    frontmattermd {
                        bodyText {
                            html
                        }
                    }
                }
            }
        }
    }
    contacts: allMarkdownRemark(
        filter: {
            frontmatter: {templateKey: {eq:"vacancycontact"}}
        }
    ){
        edges {
            node {
                frontmatter {
                    vacancycontactName
                    vacancycontactTitle
                }
                fields{
                    frontmattermd {
                        vacancycontactText {
                            html
                        }
                    }
                }
            }
        }
    }
    expertises: allMarkdownRemark(
        filter:{
            frontmatter: {templateKey:{eq:"expertise-page"}}
            fields:{langKey:{eq:$langKey}}
        }){
        edges {
            node {
                frontmatter {
                    id
                    title
                }
                fields {
                    slug
                }
            }
        }
    }
}
`;
