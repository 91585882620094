import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Link } from 'gatsby';
import Button from './../../components/buttons';
import Wrapper from './../../components/wrapper/';
import WrapperContent from './../../components/wrapperContent/';
import { ReactComponent as ArrowRight } from './../../images/Icons/arrow-next.svg';
import style from './vacancyHeader.module.scss';

const VacancyHeader = ({ intl, frontmatter: { title, duration, hoursperweek, type, expertise, expertiseSlug }}) => (
    <header className={style.vacancyHeader}>
        <Wrapper>
            <WrapperContent>
                <h1 className={style.title}>{title}</h1>
                <dl className={style.descriptionList}>
                    <dt>{intl.formatMessage({id: 'vacancy.hoursPerWeek'})}</dt>
                    <dd>{hoursperweek}</dd>
                    <dt>{intl.formatMessage({id: 'vacancy.type'})}</dt>
                    <dd>{type}</dd>
                    <dt>{intl.formatMessage({id: 'vacancy.expertise'})}</dt>
                    <dd>
                        {expertiseSlug &&
                            <Link to={expertiseSlug}>{expertise}</Link>
                        }
                        {!expertiseSlug &&
                            expertise
                        }
                    </dd>
                    <dt>{intl.formatMessage({id: 'vacancy.duration'})}</dt>
                    <dd>{duration}</dd>
                </dl>
                <div className={style.actions}>
                    <Button
                        primary={true}
                        large={true}
                        small={false}
                        type="AHREF"
                        to={`#${intl.formatMessage({ id: 'form.anchors.jobApplicationForm' })}`}
                    >
                        {intl.formatMessage({id: 'vacancy.contactLabel'})}
                    </Button>
                    <Link
                        className={style.buttonBack}
                        to={intl.formatMessage({id: 'mainmenu.url.vacancies'})}>
                        <span>{intl.formatMessage({id: 'vacancy.buttonBackLabel'})}</span>
                        <ArrowRight />
                    </Link>
                </div>
            </WrapperContent>
        </Wrapper>
    </header>
);

VacancyHeader.propTypes = {
    frontmatter: PropTypes.shape({
        title: PropTypes.string,
        duration: PropTypes.string,
        hoursperweek: PropTypes.string,
        type: PropTypes.string,
        expertise: PropTypes.string,
        expertiseSlug: PropTypes.string,
        vacancyContact: PropTypes.string,
    })
};

export default injectIntl(VacancyHeader);
